import React, { useState, useEffect, useRef } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalMyNumber from '../modals/modal-mynumber';
import ModalMyNumberConnect from '../modals/modal-mynumber-connect';
import ModalMyNumberHistory from '../modals/modal-mynumber-history';
import ModalTelegram from '../modals/modal-telegram';
import ModalEmail from '../modals/modal-email';
import ModalMyNumberSettings from '../modals/modal-mynumber-settings';
import ModalMyNumberIA from '../modals/modal-mynumber-ia';
import ModalMyNumberEL from '../modals/modal-mynumber-el';
//import ModalPayment from '../modals/modal-payment';
import Grid from '../../components/Grid';
import api from '../../services/api'
import { checkPlain } from '../../services/checkplain'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'
//import Joyride from 'react-joyride';
import ReactCountryFlag from "react-country-flag"

import {ButtonGroup,DropdownMenu,DropdownToggle,DropdownItem,UncontrolledDropdown} from 'reactstrap'

import CHATGPT from './../../assets/chatgpt.png'
import ELEVANLABS from './../../assets/ElevenLabs.png'

export default function MyNumbers() {
    const [loading, setLoading]                            = useState(false)
    const [loadingConnect, setLoadingConnect]              = useState(false)
    const [showModalWhatsApp, setShowModalWhatsApp]        = useState(false)
    const [showModalTelegram, setShowModalTelegram]        = useState(false)
    const [showModalEmail, setShowModalEmail]              = useState(false)
    const [showModalIA, setShowModalIA]                    = useState(false)
    const [showModalTest, setShowModalTest]                = useState(false)
    const [showModalSettings, setshowModalSettings]        = useState(false)
    const [showModalHistory, setShowModalHistory]          = useState(false)
    const [showModalEL, setShowModalEL]                    = useState(false)
    const [showModalPayment, setShowModalPayment]          = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])
    const [editState, setEditState]                        = useState(false)
    const [steps, setSteps]                                = useState([
        {
          target: '.content-wrapper',
          content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
          disableBeacon: true,
          placement :'top-end'
        },
        {
            target: '#btn-new',
            content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
            disableBeacon: true
          },
          {
            target: '#btn-refresh',
            content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
            disableBeacon: true
          },
          {
            target: '#btn-print',
            content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
            disableBeacon: true
          },
          {
            target: '#btn-export',
            content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
            disableBeacon: true
          },
          {
            target: '#btn-search',
            content: 'Agora vou te apresentar a nossa tela de pesquisa, basicamente todas as nossas telas são semelhantes a está, aqui você encontra todos os seus registros, e suas respectivas opções.',
            disableBeacon: true
          },
    ])
    const [rejectCall, setRejectCall]                      = useState([{idrejectcall : 0, rejectcall : 'Não', classname : 'badge badge-warning'},
                                                                       {idrejectcall : 1, rejectcall : 'Sim', classname : 'badge badge-danger'}])

    const getMyNumbers = async() => {
        await api.get('/mynumberstatus')
                 .then(response => {
                    setGridParams({ image        : {url        : 'image'},
                                    idnumber     : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                                value : '00000'},
                                                   primaryKey  : true,
                                                   visible     : false },
                                   description  : {text        : "Descrição"},
                                   number       : {text        : "Número|Token|Email",
                                                   searchDefault : true},
                                   update_at    : {text        : "Data Atualização",
                                                   dataType    : "date"}, 
                                   environment  : {text        : "Ambiente"},
                                   rejectcall   : {text        : "Rejeitar Chamadas?",
                                                   className   : "text-center",
                                                   item        : {className : "text-center",
                                                                  switch    : {data  : rejectCall,
                                                                               key   : 'idrejectcall'}}},
                                   receivecount : {text        : "Total Recebido",
                                                   className   : 'text-right',
                                                   item        : {className : 'text-right'}},
                                   sendcount    : {text        : "Total Enviado",
                                                   className   : 'text-right',
                                                   item        : {className : 'text-right'}}, 
                                   numberstatus : {text        : "Status",
                                                   className   : "text-center",
                                                   item        : {className : "text-center",
                                                                  switch    : {data  : response.data,
                                                                               key   : 'idnumberstatus'}}},
                                   Options          : {text        : "Opções",
                                                       className   : "text-center",
                                                       order       : false,
                                                       filter      : false,
                                                       item        : [
                                                                        {type  : "Custom",
                                                                                props : {tag               : "button",
                                                                                         title             : "Conectar",
                                                                                         className         : 'btn btn-sm btn-success',
                                                                                         icon              : "fa fa-globe",
                                                                                         visualConditional : [{idnumberstatus : {operator : '==',
                                                                                                              values : [0]}},
                                                                                                              {idmynumbertype : {operator : '==',
                                                                                                              values : [1]}}],
                                                                                         indexcallback     : 6}},
                                              
                                                                        {type  : "Custom",
                                                                         props : {tag               : "button",
                                                                                  title             : "Testar Conexão",
                                                                                  className         : 'btn btn-sm btn-success',
                                                                                  icon              : "fa fa-signal",
                                                                                  visualConditional : [{idnumberstatus : {operator : '==',
                                                                                                        values : [1]}},
                                                                                                       {idmynumbertype : {operator : '==',
                                                                                                        values : [1]}}],
                                                                                                        indexcallback     : 6}},
      

                     
                                                                        {type  : "Custom",
                                                                         props : {tag               : "button",
                                                                                  title             : "Excluir número",
                                                                                  className         : 'btn btn-sm btn-danger',
                                                                                  icon              : "fa fa-trash",
                                                                                  message           : "Deseja realmente excluir o telegram?",
                                                                                  visualConditional : {idmynumbertype : {operator : 'in',
                                                                                                       values : [1,5]}},
                                                                                  indexcallback     : 2}},
                                         
                                                                        {type  : "Custom",
                                                                         props : {tag               : "button",
                                                                                  title             : "Editar Token",
                                                                                  className         : 'btn btn-sm btn-warning',
                                                                                  icon              : "fa fa-passport",
                                                                                  visualConditional : {idmynumbertype : {operator : '==',
                                                                                                       values : [1]}},
                                                                                  indexcallback     : 7}},

                                                                       ]}
                      })
                  })
                  .catch(error => {
                      console.log(error)
                      ShowMessages(error)
                  })                           
        
    }

    useEffect(() => {
        getMyNumbers()
        setStatistic('maintenance\\my-numbers')
    },[])
    
    const currentTargetRef = useRef(null);
    const currentTargetRefA = useRef(null);

    const handleCloseModalWhatsapp = () => {setShowModalWhatsApp(false)
                                           setReload(true)}
    const handleCloseModalIA       = () => {setShowModalIA(false)
                                            setReload(true)}
    const handleCloseModalEL       = () => {setShowModalEL(false)
                                            setReload(true)}
    const handleCloseModalTelegram = () => {setShowModalTelegram(false)
                                            setReload(true)}
    const handleCloseModalTest     = () => {setShowModalTest(false)}    
    const handleCloseModalSettings = () => {setshowModalSettings(false)}  
    const handleCloseModalPayment  = () => {setShowModalPayment(false)}  
    const handleCloseModalHistory  = () => {setShowModalHistory(false)}  
    const handleCloseModalEmail    = () => {setShowModalEmail(false)} 
    const handleFilter             = () => {setIsOpenFilter(!isOpenFilter)}

    const importHistoric = async(idnumber,element) => {
        currentTargetRef.current =  element.target
        currentTargetRefA.current = element.currentTarget

        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente importar as 100 últimas mensagens de todas as conversas?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                currentTargetRefA.current.setAttribute('disabled',true)
                currentTargetRef.current.classList.remove('fa-comments')
                currentTargetRef.current.classList.add('fa-spin')
                currentTargetRef.current.classList.add('fa-spinner')

                await api.post('/getHistory',{idnumber})
                         .then(async(response) => { 
                            ShowMessages(response)
                            currentTargetRefA.current.removeAttribute('disabled')
                            currentTargetRef.current.classList.remove('fa-spin')
                            currentTargetRef.current.classList.remove('fa-spinner')
                            currentTargetRef.current.classList.add('fa-comments')
                        }).catch(error => {
                            ShowMessages(error)
                        })  
            }
            
          })
    }

    const showSettings = async(_id) => [
        await api.get('/mynumbers/' + _id)
                 .then(response => {
                    setData(response.data)
                    setshowModalSettings(true)
                 })
                .catch( error => {
                    ShowMessages(error)
                }) 
    ]

    const TestConnectionWhatsApp = async(_id) => {
        await api.get('/mynumbers/' + _id)
                 .then(response => {
                    setData(response.data)
                    setShowModalTest(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                })
    }

    const DeleteConnection = async(_id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir do Número | Conexão?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/myNumbers/' + _id)
                         .then((response) => {
                            if (!response.data.data.auth) 
                                ShowMessages({message : response.data.data.message,status : 200})
                            setReload(true)
                          })
                          .catch((error) => {
                              setReload(true)
                              ShowMessages(error)
                          })
            }
            
          })
    }
    
    const ConnectWhatsapp = async(_id) => {
        await api.get('/mynumbers/' + _id)
                 .then(response => {
                    setData(response.data.data || {})
                    setEditState(true)
                    setShowModalWhatsApp(true)
                })
                .catch( error => {
                    ShowMessages(error)
                })
    }
    
    const handleHistory = async(_id) => {
        await api.get('/mynumbers/history')
                 .then(async response => {
                    setData()
                 })
                  .catch( error => {
                    ShowMessages(error)
                 })
    }

    const ConnectTelegram = async(_id) => {
        await api.get('/mynumbers/' + _id)
                 .then(async response => {
                    await api.post('/api/v1/getStatusTelegram',{token : response.data.number })
                             .then(async(_status) => {  
                                setReload(true)
                                setLoadingConnect(false)
                             }).catch(error => {
                                setLoadingConnect(false)
                                ShowMessages(error)
                             })
                 })
                  .catch( error => {
                    ShowMessages(error)
                 })
    }

    const ConnectEmail = async(_id) => {
        await api.get('/mynumbers/' + _id)
                 .then(async response => {
                    await api.post('/api/v1/getStatusEmail',{password : response.data.password, port : response.data.port, smtp : response.data.servername, tls : response.data.tls, user : response.data.number })
                             .then(async(_status) => {  
                                setReload(true)
                                setLoadingConnect(false)
                             }).catch(error => {
                                setLoadingConnect(false)
                                ShowMessages(error)
                             })
                 })
                  .catch( error => {
                    ShowMessages(error)
                 })
    }

    const statusNumberConnect = async() => {
        setLoadingConnect(true)
        await api.get('/getAllMyNumbers')
                 .then(async(response) => { 
                    for (const [idx, _row] of response.data.entries()) {
                        switch (_row.idmynumbertype) {
                            case 0:
                                await api.post('/api/v1/getStatusNumber',{number : _row.number.replace(/[\W_,[a-z]+/g,""),
                                                                          hash   : _row.environment })
                                         .then(async(_status) => {  
                                            
                                         }).catch(error => {
                                 
                                         })
                                break;
                            case 1:
                                await api.post('/api/v1/getStatusTelegram',{token : _row.number })
                                         .then(async(_status) => {  
                                              
                                         }).catch(error => {
                                    
                                        })
                                break;
                            case 5:
                                    await api.post('/api/v1/getStatusEmail',{smtp     : _row.servername,
                                                                             password : _row.password,
                                                                             user     : _row.number,
                                                                             port     : _row.port,
                                                                             tls      : _row.tls}) 
                                             .then(async(_status) => {  
                                             
                                             }).catch(error => {
                    
                                            })
                                    break;
                            default:
                                break;
                        }
                    }
                    setReload(true)
                    setLoadingConnect(false)

        }).catch(error => {
           setLoadingConnect(false)
           ShowMessages(error)
        })  
    }

    const DisconnectWhatsapp =  (_id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente desconectar do WhatsApp? Ao desconectar o seu número será excluído!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.get('/mynumbers/' + _id)
                         .then(async response => {
                            await api.post('/api/v1/logout',{number : response.data.number.replace(/[\W_,[a-z]+/g,""), hash : response.data.environment})
                                     .then((response) => {
                                        if (!response.data.data.auth) 
                                            ShowMessages({message : 'Você foi desconectado com sucesso!',status : 200})
                                        setReload(true)
                                     })
                                     .catch((error) => {
                                        ShowMessages(error)
                                     })
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
          })
    }    
    
    const handleNew = async(type) => {
        let _chekPlain = await checkPlain()
        switch (type) {
            case 0://WHATSAPP
                if (_chekPlain.numbers) {
                    setEditState(false)
                    setShowModalWhatsApp(true) 
                } else 
                    ShowMessages({ status : 500, message : "Você excedeu o limite máximo de números disponiveis em seu plano, entre em contato com o suporte pare incluir novos números"})
                break;
            case 1://TELEGRAM
                if (_chekPlain.numbers) {
                    setEditState(false)
                    setShowModalTelegram(true) 
                } else 
                    ShowMessages({ status : 500, message : "Você excedeu o limite máximo de números disponiveis em seu plano, entre em contato com o suporte pare incluir novos números"})
                break;    
            case 5://EMAIL
                setEditState(false)
                setShowModalEmail(true)
                break;
            case 6://IA
                setEditState(false)
                setShowModalIA(true) 
                break;
            case 7://ELEVANLABS
                setEditState(false)
                setShowModalEL(true) 
                break;
            default:
                break;
        }

    }

    const handleEdit = async(_id) => {
        await api.get('/mynumbers/' + _id)
                 .then(async response => {
                    setEditState(true)
                    setData(response.data)
                    switch (response.data.idmynumbertype) {
                        case 1:
                            setShowModalTelegram(true)
                            break;
                        case 5:
                            setShowModalEmail(true)
                            break;
                        case 6:
                            setShowModalIA(true)
                            break;
                        case 7:
                            setShowModalEL(true)
                            break;
                        default:
                            break;
                    }
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
        
    }

    const getLayout = (_data) => {
        return (
            <div className={`box-hover box-shadow box-mynumbers ${_data.enabled ? "border-success" : ""}`} onClick={e => ([5,6,7].includes(_data.idmynumbertype)) && handleEdit(_data.idnumber)}>
                <div style={{display:"flex", gap:"10px", width:"100%"}}>
                    <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', width: '20%'}}>                    
                        <div style={{ display: 'flex', alignItems: 'center', height: '240px', flexDirection:"column" ,justifyContent:"center", padding:"10px" }}>
                            {(_data.idmynumbertype === 0) && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px',  boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff' }}className={`${_data.classname}`}>
                                    <i className="fab fa-whatsapp" style={{ fontSize: '60px', color:"#25d366" }} />
                                </div>
                            )}

                            {(_data.idmynumbertype === 1) && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px',  boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff' }}className={`${_data.classname}`}>
                                    <i className="fab fa-telegram" style={{ fontSize: '60px', color:"#0088cc" }} />
                                </div>
                            )}

                            {(_data.idmynumbertype === 2) && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px',  boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff' }}className={`${_data.classname}`}>
                                    <i className="fab fa-facebook-messenger" style={{ fontSize: '60px', color:"#0088cc" }} />
                                </div>
                            )}

                            {(_data.idmynumbertype === 3) && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px',  boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff' }}className={`${_data.classname}`}>
                                    <i className="fab fa-linkedin" style={{ fontSize: '60px', color:"#0088cc" }} />
                                </div>
                            )}

                            {(_data.idmynumbertype === 4) && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px',  boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff' }}className={`${_data.classname}`}>
                                    <i className="fab fa-discord" style={{ fontSize: '60px', color:"#7289da" }} />
                                </div>
                            )}

                            {(_data.idmynumbertype === 5) && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px',  boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff' }}className={`${_data.classname}`}>
                                    <i className="fa fa-envelope" style={{ fontSize: '60px', color:"#272829" }} />
                                </div>
                            )}

                            {(_data.idmynumbertype === 6) && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px',  boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff' }}className={`${_data.classname}`}>
                                    <img src={CHATGPT} alt="" style={{width:"85px"}}/>
                                </div>
                            )}

                            {(_data.idmynumbertype === 7) && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%', width: '50px', height: '50px',  boxShadow:'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;', color: '#fff' }}className={`${_data.classname}`}>
                                    <img src={ELEVANLABS} alt="" style={{width:"85px"}}/>
                                </div>
                            )}
                            
                        </div>
                    </div>
                    
                    <div style={{ width: '85%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width:"100%" }}>
                                    <small style={{ fontWeight: 'bold' }}>Descrição</small>
                                    <span className="chatbot-number">
                                        {_data.description}
                                    </span>
                            </div>
                        </div>
                        {(_data.idmynumbertype === 0) && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{display: 'flex', flexDirection: 'column', minWidth: '140px',}}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            Número
                                        </small>
                                        <div>
                                            <ReactCountryFlag 
                                                countryCode="BR"
                                                svg
                                                style={{
                                                    width: '1.5em',
                                                    height: '1.5em',
                                                    borderRadius:'5px'
                                                }}
                                                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                cdnSuffix="svg"
                                                title="BR" />
                                            <span>
                                                {_data.number.replace('55','')}
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex',flexDirection: 'column', minWidth: '80px',}}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            Ambiente
                                        </small>
                                        <span className="chatbot-number">{_data.environment.substr(0,20)}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:"5px" }}>
                                    <div style={{display: 'flex', flexDirection: 'column', minWidth: '100px'}}>
                                        <small style={{ fontWeight: 'bold' }}>
                                            Rejeitar Chamadas?
                                        </small>
                                        <div style={{display:"flex"}}>
                                            <span style={{width:"50px", justifyContent:"center", display:"flex"}} className={`badge ${_data.rejectcall === 'Não' ? 'badge-success' : 'badge-warning'}`}>
                                                {_data.rejectcall} 
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', minWidth: '100px'}}>
                                        <small style={{ fontWeight: 'bold' }}>
                                            Status
                                        </small>
                                        <div style={{display:"flex"}}>
                                            <span style={{width:"80px", justifyContent:"center", display:"flex"}} className={`badge ${_data.idnumberstatus === 1? 'badge-success' : 'badge-warning'}`}>
                                                {_data.numberstatus} 
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{borderTop:"1px #cacaca solid", marginTop:"5px", paddingTop:"5px", display:"flex", gap:"5px"}}>
                                    {_data.idnumberstatus === 0 && (
                                        <>
                                            <button className='btn btn-sm btn-success' title="Conectar" type='button' onClick={e => ConnectWhatsapp(_data.idnumber)}>
                                                <i className='fa fa-globe'/>
                                            </button>

                                            {/* COLOCAR EM TODOS */}
                                            <button className='btn btn-sm btn-danger' title="Excluir número" type='button' onClick={e => DeleteConnection(_data.idnumber)}>
                                                <i className='fa fa-trash'/>
                                            </button>
                                        </>
                                    )}

                                    {_data.idnumberstatus === 1 && (
                                        <>
                                            <button className='btn btn-sm btn-success' title="Testar Conexão" type='button' onClick={e => TestConnectionWhatsApp(_data.idnumber)}>
                                                <i className='fa fa-signal'/>
                                            </button>
                                            <button className='btn btn-sm btn-danger' title="Desconectar" type='button' onClick={e => DisconnectWhatsapp(_data.idnumber)}>
                                                <i className='fa fa-circle-exclamation'/>
                                            </button>
                                            <button className='btn btn-sm btn-purple padding0' title="Importação de Histórico" type='button' onClick={e => importHistoric(_data.idnumber,e)}>
                                                <i className='fa fa-comments'/>
                                            </button>    
                                        </>
                                    )}
                                    <button className='btn btn-sm btn-primary' title="Configurações do Número" type='button' onClick={e => showSettings(_data.idnumber)}>
                                        <i className='fa fa-cogs'/>
                                    </button>
                                </div>         
                            </>
                        )}

                        {(_data.idmynumbertype === 5) && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            Email
                                        </small>
                                        <div>
                                            <span>
                                                {_data.number}
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex',flexDirection: 'column',}}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            SMTP
                                        </small>
                                        <span>{_data.servername}</span>
                                    </div>
                                    <div style={{ display: 'flex',flexDirection: 'column',}}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            IMAP
                                        </small>
                                        <span>{_data.imap}</span>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop:"5px" }}>
                                    <div style={{display: 'flex', flexDirection: 'column', minWidth: '100px'}}>
                                        <small style={{ fontWeight: 'bold' }}>
                                            Porta SMTP
                                        </small>
                                        <div style={{display:"flex"}}>
                                            <span >
                                                {_data.port} 
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', minWidth: '100px'}}>
                                        <small style={{ fontWeight: 'bold' }}>
                                            Porta IMAP
                                        </small>
                                        <div style={{display:"flex"}}>
                                            <span >
                                                {_data.portimap} 
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', minWidth: '100px'}}>
                                        <small style={{ fontWeight: 'bold' }}>
                                            TLS
                                        </small>
                                        <div style={{display:"flex"}}>
                                            <span >
                                                {_data.tls ? 'Sim' : 'Não'} 
                                            </span>
                                        </div>
                                    </div>
                                    <div style={{display: 'flex', flexDirection: 'column', minWidth: '100px'}}>
                                        <small style={{ fontWeight: 'bold' }}>
                                            Status
                                        </small>
                                        <div style={{display:"flex"}}>
                                            <span style={{width:"80px", justifyContent:"center", display:"flex"}} className={`badge ${_data.idnumberstatus === 1? 'badge-success' : 'badge-warning'}`}>
                                                {_data.numberstatus} 
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{borderTop:"1px #cacaca solid", marginTop:"5px", paddingTop:"5px", display:"flex", gap:"5px"}}>
                                    <button className='btn btn-sm btn-danger' title="Excluir número" type='button' onClick={e => DeleteConnection(_data.idnumber)}>
                                        <i className='fa fa-trash'/>
                                    </button>

                                    {_data.idnumberstatus === 1 && (
                                        <>
                                            <button className='btn btn-sm btn-success' title="Testar Conexão" type='button' onClick={e => ConnectEmail(_data.idnumber)}>
                                                <i className='fa fa-signal'/>
                                            </button>
                                        </>
                                    )}

                                    <button className='btn btn-sm btn-warning' title="Editar Email" type='button' onClick={e => handleEdit(_data.idnumber)}>
                                        <i className='fa fa-envelope'/>
                                    </button>
                                </div>         
                            </>
                        )}

                        {(_data.idmynumbertype === 6) && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            Chave
                                        </small>
                                        <div>
                                            <span style={{textOverflow: "ellipsis",  maxWidth: "360px",  overflow: "hidden",  display: "block", whiteSpace: "nowrap"}}>
                                                {_data.appkey}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop:"5px", width:"100%"}}>
                                    <div style={{display: 'flex', flexDirection: 'column', minWidth: '100px'}}>
                                        <small style={{ fontWeight: 'bold' }}>
                                            Status
                                        </small>
                                        <div style={{display:"flex"}}>
                                            <span style={{width:"80px", justifyContent:"center", display:"flex"}} className={`badge ${_data.idnumberstatus === 1? 'badge-success' : 'badge-warning'}`}>
                                                {_data.numberstatus} 
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{borderTop:"1px #cacaca solid", marginTop:"5px", paddingTop:"5px", display:"flex", gap:"5px"}}>
                                    <button className='btn btn-sm btn-danger' title="Excluir chave" type='button' onClick={e => DeleteConnection(_data.idnumber)}>
                                        <i className='fa fa-trash'/>
                                    </button>

                                    <button className='btn btn-sm btn-warning' title="Editar Conexão de IA" type='button' onClick={e => handleEdit(_data.idnumber)} style={{paddingLeft:"6px"}}>
                                        <i className='fa fa-robot' />
                                    </button>
                                </div>         
                            </>
                        )}

                        {(_data.idmynumbertype === 7) && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                        <small style={{ fontWeight: 'bold' }} className="chatbot-title">
                                            App Key
                                        </small>
                                        <div>
                                            <span>
                                                {_data.appkey}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop:"5px", width:"100%"}}>
                                    <div style={{display: 'flex', flexDirection: 'column', minWidth: '100px'}}>
                                        <small style={{ fontWeight: 'bold' }}>
                                            Status
                                        </small>
                                        <div style={{display:"flex"}}>
                                            <span style={{width:"80px", justifyContent:"center", display:"flex"}} className={`badge ${_data.idnumberstatus === 1? 'badge-success' : 'badge-warning'}`}>
                                                {_data.numberstatus} 
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{borderTop:"1px #cacaca solid", marginTop:"5px", paddingTop:"5px", display:"flex", gap:"5px"}}>
                                    <button className='btn btn-sm btn-danger' title="Excluir chave" type='button' onClick={e => DeleteConnection(_data.idnumber)}>
                                        <i className='fa fa-trash'/>
                                    </button>

                                    <button className='btn btn-sm btn-warning' title="Editar Conexão de com a ElevenLabs" type='button' onClick={e => handleEdit(_data.idnumber)} style={{paddingLeft:"6px"}}>
                                        <i className='fa fa-robot'/>
                                    </button>
                                </div>         
                            </>
                        )}
                    </div>
               </div>
            </div>
        );
    };

    return (    
        <ContentWrapper>
            {/* <Joyride 
                    steps={steps} 
                    run={false} 
                    continuous ={true} 
                    disableCloseOnEsc={true}  
                    showProgress={true}  
      
                    locale={{back: 'Voltar', close: 'Fechar', last: 'Último', next: 'Próximo', open: 'Abrir', skip: 'Pular' }}
                    styles={{  overlay : { zIndex: 2000 },
                               options : { zIndex: 3000 },
                               buttonNext: {
                                backgroundColor: '#104e5a',
                               },
                               buttonBack: {
                                backgroundColor: '#104e5a',
                                borderRadius:"3px",
                                color:"#fff"
                               }}}/> */}
            {loading && (
                <Loading loading={loading}/>
            )}
            <ModalMyNumberConnect showModal={showModalWhatsApp} handleCloseModal = {handleCloseModalWhatsapp} handleReload={setReload} data={editState ? data : {}} editState={editState}/>
            <ModalTelegram showModal={showModalTelegram} handleCloseModal = {handleCloseModalTelegram} handleReload={setReload} data={editState ? data : {}} editState={editState}/>
            <ModalMyNumberIA showModal={showModalIA} handleCloseModal = {handleCloseModalIA} handleReload={setReload} data={editState ? data : {}} editState={editState}/>
            <ModalEmail showModal={showModalEmail} handleCloseModal = {handleCloseModalEmail} handleReload={setReload} data={editState ? data : {}} editState={editState}/>
            <ModalMyNumber showModal={showModalTest} data={data} handleCloseModal = {handleCloseModalTest} handleReload={setReload}/>
            <ModalMyNumberSettings showModal={showModalSettings} data={data} handleCloseModal = {handleCloseModalSettings} handleReload={setReload}/>
            <ModalMyNumberHistory showModal={showModalHistory} data={data} handleCloseModal = {handleCloseModalHistory} />
            <ModalMyNumberEL showModal={showModalEL} data={data} handleCloseModal = {handleCloseModalEL} editState={editState}/>
            {/* <ModalPayment showModal={showModalPayment} data={data} handleCloseModal = {handleCloseModalPayment} handleReload={setReload}/> */}
            
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <ButtonGroup>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret color="success">
                                        <i className="fa fa-plus"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem onClick={e => handleNew(0)}>
                                            <i className="fab fa-whatsapp"></i> WhatsApp
                                        </DropdownItem>
                                        <DropdownItem onClick={e => handleNew(1)}>
                                            <i className="fab fa-telegram"></i> Telegram
                                        </DropdownItem>
                                        <DropdownItem onClick={e => handleNew(5)}>
                                            <i className="fa fa-envelope"></i> Email
                                        </DropdownItem>
                                        <DropdownItem onClick={e => handleNew(6)}>
                                            <i className="fa fa-robot"></i> Inteligência Artificial
                                        </DropdownItem>
                                        <DropdownItem onClick={e => handleNew(7)}>
                                            <i className="fa fa-voicemail"></i> Texto para Voz
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </ButtonGroup>
                            <button className="btn btn-primary" id="btn-refresh" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons && ( 
                                <>
                                    <button className="btn btn-warning" id="btn-check" title="Checar as Conexões" style={{marginLeft:"3px"}} onClick={e => statusNumberConnect()}><i className={`${loadingConnect ? 'fa fa-spinner fa-spin' : 'fa fa-tower-cell'}`}></i></button>
                                    <button className="btn btn-dark" id="btn-print" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info"  id="btn-export" title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" id="btn-search" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            )}
                            {/* <button className="btn btn-purple" title="Histórico de Conexões" style={{marginLeft:"3px"}} onClick={e => handleHistory()}><i className="fa fa-square-poll-vertical"></i></button> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter}/>
                <Grid url                 = '/mynumbers' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      getGridLayout       = {getLayout}
                      filters             = {filters}
                      filename            = "Números"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                     // callbackCellClick   = {ConnectWhatsapp}
                      callbackButtons     = {[]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}