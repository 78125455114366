import React from "react";

import { isAuthenticated } from "./services/auth";
import ChatDataProvider from './components/messageView/chatContext'

import Error404 from "./pages/errors/404";
import Alert from "./pages/maintenance/alert";
import AlertAll from "./pages/maintenance/alerts-all";
import Bill from "./pages/maintenance/bill";
import BillsToPay from "./pages/maintenance/billstopay";
import Company from "./pages/maintenance/company";
import Employees from "./pages/maintenance/employees";
import Leads from "./pages/maintenance/leads";
import GroupLink from "./pages/maintenance/group-link";
import NewsletterSystem from "./pages/maintenance/newsletter-system";
import PermissionsGroup from "./pages/maintenance/permissions-group";
import PermissionsUser from "./pages/maintenance/permissions-user";
import Profile from "./pages/maintenance/profile";
import Departaments from "./pages/maintenance/departaments";
import UserGroup from "./pages/maintenance/user-group";
import ReportBill from "./pages/reports/report-bill";
import AdminInstagram from "./pages/maintenance/admin/instagram";
import BillAdmin from "./pages/maintenance/admin/bill";
import Campaign from "./pages/maintenance/campaign";
import CampaignGroup from "./pages/maintenance/campaign-group";
import ChartOfAccounts from "./pages/maintenance/chart-of-accounts";
import Holidays from "./pages/maintenance/holidays";
import Import from "./pages/maintenance/import";
import Email from "./pages/maintenance/integration-email";
import MyNumbers from "./pages/maintenance/my-numbers";
import ReportSend from "./pages/maintenance/report-send";
import Settings from "./pages/maintenance/settings";
import WhatsappGroups from "./pages/maintenance/whatsapp-groups";
import AutomationHistory from "./pages/maintenance/automation-history";
import WebHook from "./pages/maintenance/webhook";
import DashboardClients from "./pages/maintenance/admin/dashboard-clients";
import DashboardCampaign from "./pages/maintenance/admin/dashboard-campaign";
import DashboardDatabase from "./pages/maintenance/admin/dashboard-database";
import ChatBot from "./pages/maintenance/chatbot";
import Workflow from "./pages/maintenance/workflow-chat";
import Messages from "./pages/maintenance/messages";
import Plains from "./pages/maintenance/admin/plains";
import Tags from "./pages/maintenance/tags";
import Clients from "./pages/maintenance/admin/clients";
import WhatsappNumbers from "./pages/maintenance/admin/whatsapp-numbers";
import FastMessages from "./pages/maintenance/fast-messages";
import AfiliatePage from './pages/maintenance/affiliate';
import MyAfiliates from './pages/maintenance/my-afiliates';
import Shop from './pages/maintenance/shop';
import GroupEntry from './pages/maintenance/group-entry';
import Attendance from "./components/validators/Attendance";
import Users from './pages/maintenance/users';
import Payment from './pages/maintenance/payment'
import Ecommerce from './pages/maintenance/ecommerce'
import PABXVIVO from './pages/maintenance/pabx-vivo'

const getObject = (uri, id, props, idmenu) => {
  switch (uri) {
    case "/employees"             : return <Employees />;
    case "/company"               : return <Company />;
    case "/profile"               : return <Profile />;
    case "/user-groups"           : return <UserGroup />;
    case "/bill"                  : return <Bill />;
    case "/alert-all"             : return <AlertAll />;
    case "/newsletter-system"     : return <NewsletterSystem />;
    case "/billstopay"            : return <BillsToPay />;
    case "/chart-of-accounts"     : return <ChartOfAccounts />;
    case "/holidays"              : return <Holidays />;
    case '/alert'                 : return(<Alert id={id} props={props}/>)
    case "/usergroup/permissions" : return <PermissionsGroup id={id} props={props} />;
    case "/user/permissions"      : return <PermissionsUser id={id} props={props} />;
    case "/receipt"               : return <ReportBill id={id} props={props} />;
    case "/leads"                 : return <Leads />;
    case "/my-numbers"            : return <MyNumbers />;
    case "/import"                : return <Import />;
    case "/campaign"              : return <Campaign />;
    case "/campaign-group"        : return <CampaignGroup />;
    case "/group-link"            : return <GroupLink />;
    case "/email"                 : return <Email />;
    case "/report-send"           : return <ReportSend />;
    case "/groups"                : return <WhatsappGroups />;
    case "/settings"              : return <Settings />;
    case "/admin-instagram"       : return <AdminInstagram />;
    case "/admin-bill"            : return <BillAdmin />;
    case "/shop"                  : return <Shop />;
    case "/e-commerce"             : return <Ecommerce />;
    case "/chatbot"               : return <ChatBot />;
    case "/workflow"              : return <Workflow id={id} props={props} />;
    case "/chat-bot-history"      : return <ChatDataProvider>
                                              <AutomationHistory/>
                                           </ChatDataProvider>;
    case "/plains"                : return <Plains/>;
    case "/dashboard-clients"     : return <DashboardClients/>;
    case "/dashboard-campaign"    : return <DashboardCampaign/>;
    case "/dashboard-database"    : return <DashboardDatabase/>;
    case "/webhook"               : return <WebHook/>;
    case '/afiliate'              : return <AfiliatePage />;
    case '/my-afiliates'          : return <MyAfiliates />;
    case '/admin-whatsapp'        : return <WhatsappNumbers />;
    case "/clients"               : return <Clients/>;
    case "/group-entry"           : return <GroupEntry/>;
    case "/users"                 : return <Users/>;
    case "/payment"               : return <Payment/>;

    case "/fast-messages"         : return <Attendance>
                                                <FastMessages/>
                                           </Attendance>;
    case "/departaments"          : return <Attendance>
                                                <Departaments />
                                           </Attendance>;
    case "/tags"                  : return <Attendance>
                                                <Tags/>
                                           </Attendance>;
    case "/messages"              : return <Attendance>
                                                <ChatDataProvider>
                                                    <Messages props={props}/>
                                                </ChatDataProvider>
                                           </Attendance>

    //ROTAS DA LOJA
    case "/vivo-pabx"                  : return <PABXVIVO/>
    default:
      return <Error404 />;
  }
};

export default function getComponent(uri, id, props, idmenu) {
  return isAuthenticated ? getObject(uri, id, props, idmenu) : <Error404 />;
}
