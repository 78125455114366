import { memo, useEffect, useRef, useState } from "react";
import Wavesurfer from "wavesurfer.js";
//import * as WaveformRegionsPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions";
// import defaultAudio from "../assets/audio/chat-alert.mp3";

const Waveform = ({ waveFormInstance, url, destroyInstance, pauseAudio, isModal, isBase64,backgroundColor,maxSize=250, objAudio, setObjAudio}) => {
    const waveform = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [minutes, setMinutes] = useState("00");
    const [seconds, setSeconds] = useState("00");

    const convertBase64 = (base64Audio) => {
        let audioData
        try {
            audioData = atob(base64Audio); // Decodifica a string base64
        } catch(e) {
            console.log('ERRO AO CONVERTER A BASE64 DO AUDIO',base64Audio)
        }
        
        if (audioData) {
            const arrayBuffer = new ArrayBuffer(audioData.length);
            const view = new Uint8Array(arrayBuffer);

            for (let i = 0; i < audioData.length; i++) {
                view[i] = audioData.charCodeAt(i) & 0xff;
            }

            const blob = new Blob([arrayBuffer], { type: 'audio/ogg' })
            return URL.createObjectURL(blob)
        } else 
            return base64Audio
    }

    const playAudio = (e) => {
       e.stopPropagation()
       typeof objAudio === 'object' && objAudio.map(_obj => {
            if (waveform.current !== _obj)
                _obj.pause();
        })

        if (waveform.current.isPlaying()) {
            setIsPlaying(false);
            waveform.current.pause();
        } else {
            setIsPlaying(true);
            waveform.current.play();
        }
    };

    useEffect(() => {
        if (url) {
            if (!waveform.current) {
                waveform.current = Wavesurfer.create({
                    container: `#${waveFormInstance}`,
                    height: 18,      
                    "splitChannels": false,
                    normalize: true,
                    waveColor: "#b5b5b5",
                    progressColor: "#515151",
                    cursorColor: "#0d6efd",
                    "cursorWidth": 2,
                    "barWidth": 2,
                    "barGap": 1,
                    "barRadius": null,
                    "barHeight": 1.5,
                    "barAlign": "",
                    "minPxPerSec": 1,
                    fillParent: true,
                    "interact": true,
                    "dragToSeek": false,
                    "hideScrollbar": false,
                    "audioRate": 1,
                    "autoScroll": true,
                    "autoCenter": true,
                    "sampleRate": 8000
                  //  plugins: [WaveformRegionsPlugin.create({ maxLength: 90 })],
                });

                if (setObjAudio)
                    setObjAudio(objAudio => objAudio.concat(waveform.current))
            }
            let _url = url
            console.log('URL DO AUDIO',isBase64,_url.toString().substring(0,10))
            if (isBase64)
                _url = convertBase64(url)
            console.log('URL DO CONVERTIDA',_url.toString().substring(0,10))

            waveform.current.load(_url);
            console.log('URL CARREGADA')
            waveform.current.on("audioprocess", () => {
                console.log('PROCESSANDO URL')
                if (waveform.current.isPlaying) {
                    let seconds = Math.floor(waveform.current.getDuration() - waveform.current.getCurrentTime());
                    var h = Math.floor(seconds / 3600);
                    var m = Math.floor((seconds - (h * 3600)) / 60);
                    var s = seconds - (h * 3600) - (m * 60);
                  
                    h = h < 10 ? '0' + h : h;
                    m = m < 10 ? '0' + m : m;
                    s = s < 10 ? '0' + s : s;

                        setMinutes(m);
                        setSeconds(s);
                }
            })

            waveform.current.on("ready", () => {
                console.log('URL LEITURA')
                 let seconds = Math.floor(waveform.current.getDuration() - waveform.current.getCurrentTime());
                    var h = Math.floor(seconds / 3600);
                    var m = Math.floor((seconds - (h * 3600)) / 60);
                    var s = seconds - (h * 3600) - (m * 60);
                  
                    h = h < 10 ? '0' + h : h;
                    m = m < 10 ? '0' + m : m;
                    s = s < 10 ? '0' + s : s;
                    
                        setMinutes(m);
                        setSeconds(s);
            });

      waveform.current.on("finish", () => {
        console.log('URL FINALIZADA')
        setIsPlaying(false);
      });
    }
    }, [url, waveFormInstance]);

    useEffect(() => {
        if (destroyInstance) {
            waveform.current.destroy();
            waveform.current = null;
        }
    }, [destroyInstance]);

    useEffect(() => {
        if (pauseAudio) {
            waveform.current.pause();
        }
    }, [pauseAudio]);

    return (
        <>
            {isModal ? 
                <div style={{width:"100%"}}>
                    <div className="text-end" style={{padding:"1px", maxWidth:`${maxSize}px`, display:"flex",flexDirection:"column"}}>
                        <div className="d-flex align-items-center justify-content-around" style={{borderRadius:"30px", background:`${backgroundColor || '#fff'}`}}>
                            <div style={{padding:"10px"}}>
                                <button type="button" className='btn btn-sm btn-secondary' onClick={e => playAudio(e)} style={{borderRadius:"50%"}}>
                                    {isPlaying ? (
                                        <i className="fa fa-pause"></i>
                                    ) : (
                                        <i className="fa fa-play"></i>
                                    )}
                                </button>
                            </div>
                            <div style={{ width: "80%", scrollbarWidht: "thin" }}>
                                <div id={waveFormInstance} />
                            </div>
                        </div> 
                    </div>
                    <div style={{height: "15px", display:"flex", justifyContent:"end", maxWidth:`${maxSize}px`, width:"100%", marginTop:"-18px", paddingRight:'20px'}}>
                        <span style={{fontSize:"10px"}}>{minutes}:{seconds}</span>
                    </div> 
                </div>
            : 
            <div className="text-end" style={{padding:"1px", width:"115px"}}>
                <div className="d-flex align-items-center justify-content-around">
                    <div>
                        <button type="button" className='btn btn-sm btn-secondary button-audio' onClick={e => playAudio(e)} style={{borderRadius:"50%"}}>
                            {isPlaying ? (
                                <i className="fa fa-pause" style={{fontSize:"6px"}}></i>
                            ) : (
                                <i className="fa fa-play" style={{fontSize:"6px", marginLeft:"2px"}}></i>
                            )}
                        </button>
                    </div>
                    <div style={{ width: "80%", scrollbarWidht: "thin", marginLeft:"5px" }}>
                        <div id={waveFormInstance} />
                    </div>
                    <div style={{marginTop:"5px",height: "12px"}}>
                        <span style={{fontSize:"6px"}}>{minutes}:{seconds}</span>
                    </div>
                </div>  
            </div>
            }
        </>
    );
};

export default memo(Waveform);
