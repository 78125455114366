import React, {useState, useEffect } from 'react'
import { IDCOMPANY, ReadDataStorage} from './../../services/auth'
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'

const Sidebar = ({handleShowMenu,ExportFile,onSave, loading}) => {
    const [allowSystemAttendance, setAllowSystemAttendance]  = useState(false)
    const [officialAPI, setOfficialAPI]                      = useState(false)

    const getData = async() => {
        await api.get('/company/' + ReadDataStorage(IDCOMPANY))
                 .then(response => {
                    if (response.ok) {
                        setAllowSystemAttendance(response.data?.settings?.allowSystemAttendance || false)
                        setOfficialAPI(response.data?.settings?.officialAPI || false)
                    }
                  })
                 .catch(error =>{
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        getData()
    },[])

    const onDragStart = (event, nodeType, nodeInfo) => {
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.setData("nodeInfo", JSON.stringify(nodeInfo));
        event.dataTransfer.effectAllowed = "move";
    };

    const onDragEnd = (event, nodeType, nodeInfo) => {
        handleShowMenu()
    };

    return (
        <aside className="sidebar" style={{ padding: "10px", gap: "5px", display: "flex", flexDirection: "column", overflow: "hidden", width: "330px" }}>
            <div style={{ display: "flex", gap: "5px", marginBottom: "15px" }}>
                <button className="btn btn-warning" style={{ width: "33%" }} type="button" onClick={e => document.querySelector('#importFile').click()}>
                    <i className="fa fa-file-import" /> Importar
                </button>
                <button className="btn btn-primary" style={{ width: "33%" }} type="button" onClick={e => ExportFile()}>
                    <i className="fa fa-file-export" /> Exportar
                </button>
                <button className="btn btn-success" style={{ width: "33%" }} onClick={onSave} disabled={loading}>
                    <i className={`fa ${loading ? "fa-spin fa-spinner" : "fa-save"}`} /> {loading ? 'Salvando' : 'Salvar'}
                </button>
            </div>
            <div style={{overflowX:"hidden", overflowY:"auto"}}>
                <div style={{ display: "flex", gap: "5px" }}>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "messageNode", {
                        message: "",
                        parentId: -1,
                        node: -1,
                        targetId: -1,
                        objectType: 1
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-keyboard" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Mensagem de Texto</span>
                        </div>
                    </div>

                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "audioNode", {
                        node: -1,
                        audio: null,
                        objectType: 3
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-microphone" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Mensagem de Áudio</span>
                        </div>
                    </div>

                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "fileNode", {
                        parentId: -1,
                        targetId: -1,
                        node: -1,
                        files: [],
                        description: '',
                        message: '',
                        origin: 0,
                        objectType: 2
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-file" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Arquivos</span>
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", gap: "5px" }}>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "buttonNode", {
                        parentId: -1,
                        targetId: -1,
                        node: -1,
                        body: '',
                        footer: '',
                        title: '',
                        closeInteraction: 10,
                        redirectLead: 3,
                        buttons: [],
                        objectType: 5
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-mobile-screen" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Botões</span>
                        </div>
                    </div>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "LocationNode", {
                        parentId: -1,
                        targetId: -1,
                        node: -1,
                        latitude: 0,
                        longitude: 0,
                        objectType: 19
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-map-location" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Localização</span>
                        </div>
                    </div>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "contactNode", {
                        parentId: -1,
                        targetId: -1,
                        node: -1,
                        objectType: 4,
                        files: []
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-id-card" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Contato</span>
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", gap: "5px" }}>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "actionNode", {
                        parentId: -1,
                        node: -1,
                        targetId: -1,
                        objectType: 8,
                        timeToAction: 30,
                        selectedTags: [],
                        actionType: 0
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-person-running" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Evento de Ação</span>
                        </div>
                    </div>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "endNode", {
                        parentId: -1,
                        targetId: -1,
                        node: -1,
                        isClosed: true,
                        isHuman: false,
                        timernextInteraction: 0,
                        objectType: 9
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-flag-checkered" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Fim do Fluxo</span>
                        </div>
                    </div>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "inputNode", {
                        parentId: -1,
                        targetId: -1,
                        node: -1,
                        objectType: 7,
                        tagName: '',
                        validation: '',
                        tagLink: '',
                        commandEnd: ''
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-database" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Capturar Dados</span>
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", gap: "5px" }}>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "emailNode", {
                        parentId: -1,
                        targetId: -1,
                        node: -1,
                        objectType: 16,
                        tagName: '',
                        validation: '',
                        tagLink: '',
                        commandEnd: ''
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-envelope" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Enviar Email</span>
                        </div>
                    </div>

                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "whatsappNode", {
                        parentId: -1,
                        targetId: -1,
                        node: -1,
                        message: '',
                        audio: null,
                        objectTypeW: -1,
                        origin: -1,
                        selectedFiles: [],
                        activeContact: false,
                        whatsapp: '',
                        Url: '',
                        objectType: 17
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fab fa-whatsapp" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>WhatsApp</span>
                        </div>
                    </div>

                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "webhookNode", {
                        parentId: -1,
                        node: -1,
                        targetId: -1,
                        objectType: 10,
                        url: '',
                        description: '',
                        method: '',
                        requestType: '',
                        conditionData: [],
                        body: ''
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-satellite-dish" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Webhook</span>
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", gap: "5px" }}>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "randomizeNode", {
                        parentId: -1,
                        node: -1,
                        targetId: -1,
                        objectType: 13,
                        percents: []
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-shuffle" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Randomizador</span>
                        </div>
                    </div>

                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "triggerNode", {
                        parentId: -1,
                        node: -1,
                        targetId: -1,
                        objectType: 14,
                        workflow: '',
                        idautomation: 0
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-diagram-project" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Gatilho</span>
                        </div>
                    </div>

                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "bridgeNode", {
                        parentId: -1,
                        node: -1,
                        targetId: -1,
                        objectType: 15,
                        idattendance: 0,
                        attendance: '',
                        isClosed: true,
                        isHuman: false
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-tty" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Ponte de Atendimento</span>
                        </div>
                    </div>
                </div>

                <div style={{ display: "flex", gap: "5px" }}>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "IANode", {
                        parentId: -1,
                        node: -1,
                        targetId: -1,
                        objectType: 18,
                        percents: []
                    })}>
                        <div className="button-node button-node-option">
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-robot" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Inteligência Artificial</span>
                        </div>
                    </div>
                    <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "conditionNode", {
                        parentId: -1,
                        node: -1,
                        targetId: -1,
                        objectType: 11,
                        condition: '',
                        conditionId: -1,
                        type: '',
                        tagCondition: '',
                        options: [],
                        tagLead: '',
                        conditionData: []
                    })}>
                        <div className="button-node button-node-option" >
                            <div className="d-flex justify-content-center ">
                                <i className="fa fa-bezier-curve" style={{ fontSize: "26px", color: "#6f42c1" }} />
                            </div>
                            <span style={{ color: "#6f42c1", textAlign: "center" }}>Condição</span>
                        </div>
                    </div>
                    {allowSystemAttendance && (
                        <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "redirectNode", {
                            node: -1,
                            parentId: -1,
                            targetId: -1,
                            objectType: 12,
                            isClosed: true,
                            isHuman: true,
                            departament: -1,
                            departamentName: ''
                        })}>

                            <div className="button-node button-node-option" >
                                <div className="d-flex justify-content-center ">
                                    <i className="fa fa-headset" style={{ fontSize: "26px", color: "#6f42c1" }} />
                                </div>
                                <span style={{ color: "#6f42c1", textAlign: "center" }}>Atendimento</span>
                            </div>
                        </div>
                    )}

                    {officialAPI && (
                        <div className="dndnode" draggable onDragEnd={onDragEnd} onDragStart={(event) => onDragStart(event, "listNode", {
                            parentId: -1,
                            targetId: -1,
                            node: -1,
                            body: '',
                            footer: '',
                            description: '',
                            title: '',
                            list: [],
                            objectType: 6
                        })}>
                            <div className="button-node button-node-option" >
                                <div className="d-flex justify-content-center ">
                                    <i className="fa fa-list" style={{ fontSize: "26px", color: "#6f42c1" }} />
                                </div>
                                <span style={{ color: "#6f42c1", textAlign: "center" }}>Lista de Opções</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </aside>
    );
};

export default Sidebar;
