import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'

import ModalFastMessage from '../../../pages/modals/modal-fast-message';
import ModalCoordenates from '../../../pages/modals/modal-coordenates';
import ModalEditMessage from '../../../pages/modals/modal-edit-message';

import ShowMessages from '../../../components/Exceptions'
import Swal from 'sweetalert2'
import { useChatData } from './../chatContext'

import api from '../../../services/api'
import {  differenceInMinutes } from 'date-fns'

export default function OptionChat({ props, allocMessage }) {
    const [dataCoordenates, setDataCoordenates]           = useState({})
    const [showModalCoordinates, setShowModalCoordinates] = useState(false)
    const [dataFastMessage, setDataFastMessage]           = useState([])
    const [showModalFastMessage, setShowModalFastMessage] = useState(false)
    const [showModalEditMessage, setShowModalEditMessage] = useState(false)
    const [dataEdit, setDataEdit]                         = useState({})

    const { to, number } = useChatData()

    const editMessage = async (idmessage, isEdit) => {
        await api.post('/getMessageFromId', { idmessage })
            .then(async (response) => {
                if (response.data) {
                    setDataEdit({
                        isEdit,
                        idmessage,
                        to,
                        number,
                        messageList: response.data.editedhistory ? [...response.data.editedhistory, {
                            body: response.data.body,
                            create_at: response.data.create_at
                        }] : [{
                            body: response.data.body,
                            create_at: response.data.create_at
                        }]
                    })
                    setShowModalEditMessage(true)
                }
            })
            .catch((error) => {
                ShowMessages(error)
            })
    }

    const DeleteMessage = async(props) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente apagar a mensagem selecionada?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await api.post('/deleteMessage', {
                    number : number,
                    chatnumber : to,
                    idmessage : props.idmessage
                })
                    .then((response) => {                       
                        ShowMessages(response)
                    }).catch(error => {
                        ShowMessages(error)
                    })
            }

        })
    }

    const handleCoordinates = (latitude, longitude) => {
        setDataCoordenates({ latitude, longitude })
        setShowModalCoordinates(true)
    }
    
    const createFastMessage = async(_conversation) => {
        setDataFastMessage(_conversation)
        setShowModalFastMessage(true)
    }

    const handleCloseModalCoordenates = () => { setShowModalCoordinates(false) }
    const handleCloseModalFastMessage = () => { setShowModalFastMessage(false)}
    const handleCloseModalEdit        = () => { setShowModalEditMessage(false) }

    return (
        <>
            {showModalCoordinates && (<ModalCoordenates showModal={showModalCoordinates} handleCloseModal={handleCloseModalCoordenates} data={dataCoordenates} />)}
            {showModalFastMessage && (<ModalFastMessage showModal={showModalFastMessage} editState={false} handleCloseModal={handleCloseModalFastMessage} data={dataFastMessage}/>)}
            {showModalEditMessage && (<ModalEditMessage showModal={showModalEditMessage} handleCloseModal={handleCloseModalEdit} data={dataEdit} allocMessage={allocMessage} />)}

            <Dropdown title='Opções' variant="success" style={{ textAlign: "right", display: "flex", height: "16px", width:"100%", justifyContent:'flex-end', cursor:"pointer",marginTop:"-15px"}} onClick={e => e.stopPropagation()}  className='show-over'>
                <Dropdown.Toggle style={{ padding: "0px", marginTop:"18px" }} variant="transparent" className='btn-transparent-chatbot btn-toogle-custom' >
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ padding: "0px" }} >
                    {differenceInMinutes(new Date(), props.create_at) < 15 && (!props.isreceive) && (
                        <>
                            <Dropdown.Item href="#" onClick={e => editMessage(props.idmessage, true)}>
                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <em className="fa fa-edit"></em>
                                    <span>Editar Mensagem</span>
                                </div>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={e => DeleteMessage(props, true)}>
                                <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                    <em className="fa fa-trash"></em>
                                    <span>Apagar Mensagem</span>
                                </div>
                            </Dropdown.Item>
                        </>
                    )}
                
                    {props.edited && (
                        <Dropdown.Item href="#" onClick={e => editMessage(props.idmessage, false)}>
                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <em className="fa fa-grip-lines"></em>
                                <span>Visualizar Alterações</span>
                            </div>
                        </Dropdown.Item>
                    )}

                    {(props.type === 'location') && (
                        <Dropdown.Item href="#" onClick={e => handleCoordinates(props.location.latitude, props.location.longitude)}>
                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                <em className="fa fa-location-dot"></em>
                                <span>Visualizar Cordenadas</span>
                            </div>
                        </Dropdown.Item>
                    )}
                    <Dropdown.Item href="#" onClick={e => createFastMessage(props)}>
                        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                            <em className="fa fa-forward-fast"></em>
                            <span>Criar Mensagem Rápida</span>
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    )
}