import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, } from 'reactstrap';
import Draggable from 'react-draggable';

import { Modal,  ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';
import loadingButton  from '../layouts/Buttons'


class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalVIVOPABX({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                       = useState(false)
    const [description, setDescription]               = useState('')
    const [imap, setIMAP]                             = useState('')
    const [password, setPassword]                     = useState('')
    const [email, setEmail]                           = useState('')
    const [port, setPort]                             = useState('')
    const [tls, setTLS]                               = useState(false)
    const [isgmail, setIsGmail]                       = useState(false)
    const [uservivo,setUserVivo]                      = useState('')
    const [passwordvivo, setPasswordVivo ]            = useState('')
    const [idautomationchat, setIdautomationChat]     = useState('')

    const [automationChatData, setAutomationChatData] = useState([])

    const setData = (obj,clear) => {
        setDescription(clear ? '' : obj.description)
        setIMAP(clear ? '' : obj.imap)
        setPassword(clear ? '' : obj.password)
        setEmail(clear ? '' : obj.email)
        setPort(clear ? '' : obj.port)
        setTLS(clear ? false : obj.tls)
        setIsGmail(clear ? false : obj.isgmail)
        setUserVivo(clear ? '' : obj.uservivo)
        setPasswordVivo(clear ? '' : obj.passwordvivo)
        setIdautomationChat(clear ? '' : obj.idautomationchat)
    }
 
    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-vivopabx'),document.querySelector('#submit-vivopabx').children[0].classList,document.querySelector('#submit-vivopabx').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {description,
                         imap,
                         password,
                         email,
                         port,
                         tls,
                         isgmail,
                         uservivo,
                         passwordvivo,
                         idautomationchat}
                     
                     
            if (editState)
                await api.put('/pabx-vivo/' + data.idpabxvivo, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-vivopabx'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-vivopabx'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-vivopabx'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/pabx-vivo', _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-vivopabx'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-vivopabx')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-vivopabx'))
                            ShowMessages(error) 
                        })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-vivopabx'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        const getData = async() => {
            setLoading(true)
            await api.get('/getChatBotGroup')
                     .then(async response => {
                        setAutomationChatData(response.data || [])
                        if (editState) 
                            setData(data,false)
                        else 
                            setData({},true)
                        setLoading(false)
                     })
                     .catch( error => {
                        ShowMessages(error)
                        setLoading(false) 
                     })
        }

        getData()
        setStatistic('modals\\modal-vivopabx')
    },[data,editState,showModal])


    return (
      
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" > 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-headset"></i> Propriedades do VIVO PABX
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    {loading && (
                        <Loading loading={loading} addClass="heigth330px"/>
                    )}
                    <div className={`box-shadow ${loading ? "hidden" : ""}`}>               
                        <div>
                            <label>Descrição</label><label className="text-required">*</label>
                            <div className="input-group">
                                 <TextInput  
                                    name="description"
                                    id="description"
                                    type="text"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe uma descrição!" }}
                                    value={description}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setDescription(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div>
                            <label>Servidor IMAP</label><label className="text-required">*</label>
                            <div className="input-group">
                                 <TextInput  
                                    name="imap"
                                    id="imap"
                                    type="text"
                                    required
                                    maxLength={150}
                                    errorMessage={{ required: "Por favor, informe o servidor IMAP!" }}
                                    value={imap}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setIMAP(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div>
                            <label>Email</label><label className="text-required">*</label>
                            <div className="input-group">
                                 <TextInput  
                                    name="email"
                                    id="email"
                                    type="text"
                                    required
                                    maxLength={150}
                                    errorMessage={{ required: "Por favor, informe o email!" }}
                                    value={email}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setEmail(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div style={{display:"flex", gap:"5px"}}>
                            <div>
                                <label>Senha</label><label className="text-required">*</label>
                                <TextInput  
                                    name="password"
                                    id="password"
                                    type="password"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe a senha!" }}
                                    value={password}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setPassword(e.target.value)}
                                    autoComplete="off"
                                    />
                            </div>
                            <div>
                                <label>Porta IMAP</label><label className="text-required">*</label>
                                <TextInput  
                                    name="port"
                                    id="port"
                                    type="number"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe uma porta!" }}
                                    value={port}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setPort(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div style={{display:"flex", gap:"45px"}}>
                            <div style={{display:"flex", alignItems:"center",marginTop:"10px"}}> 
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={tls} value={tls} onChange={(e) => setTLS(!tls)}/>
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <span style={{marginLeft:"5px",marginTop:"5px"}}>TLS?</span>
                            </div>

                            <div style={{display:"flex", alignItems:"center",marginTop:"10px"}}> 
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={isgmail} value={isgmail} onChange={(e) => setIsGmail(!isgmail)}/>
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <span style={{marginLeft:"5px",marginTop:"5px"}}>Email do Google Workspace?</span>
                            </div>
                        </div>

                        <div>
                            <label>Email | Usuário Vivo</label><label className="text-required">*</label>
                            <div className="input-group">
                                 <TextInput  
                                    name="uservivo"
                                    id="uservivo"
                                    type="text"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe o usuário!" }}
                                    value={uservivo}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setUserVivo(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div>
                            <label>Senha</label><label className="text-required">*</label>
                            <div className="input-group">
                                 <TextInput  
                                    name="passwordvivo"
                                    id="passwordvivo"
                                    type="password"
                                    required
                                    maxLength={100}
                                    errorMessage={{ required: "Por favor, informe a senha!" }}
                                    value={passwordvivo}
                                    className="form-control "
                                    autoFocus
                                    onChange={e => setPasswordVivo(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        </div>

                        <div style={{display:"flex", gap:5}}>
                            <div style={{width:"100%"}}>
                                <label>Chatbot</label>
                                <select className='form-select' value={idautomationchat} onChange={e => setIdautomationChat(e.target.value)} required>
                                    <option value="">Selecione</option>
                                    {automationChatData.map((_item) => {
                                        return (<option value={_item.idautomationchat}>{_item.title}</option>)
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-vivopabx' className={`btn-theme ${loading ? "hidden" : ""}`} >
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}